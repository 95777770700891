export enum Path {
  MAIN = '/',
  AUTH = 'auth',
  SIGN_IN = 'sign-in',
  SIGN_OUT = 'sign-out',
  RESET_PASSWORD = 'restore-password',
  SEND_RESET_PASSWORD = 'send-restore-password',
  TWO_FACTOR = 'two-factor',
  SWITCH_TWO_FACTOR = 'switch-two-factor',
  CHANGE_PASSWORD = 'change-password',
  VERIFY_STATUS = 'verify-status',
  VERIFY_PHONE = 'verify-phone',
  CHANGE_STATUS = 'change-status',
  CHECK_VERIFY_PHONE_CODE = 'check-verify-phone',
  VERIFY_EMAIL = 'verify-email',
  CHECK_VERIFY_EMAIL_CODE = 'check-verify-email',
  SEND_CODE = 'send-code',
  CURRENT_USER = 'user',
  REFRESH = 'refresh',
  DASHBOARD = 'dashboard',
  PROFILE = 'profile',
  NOT_FOUND = '404',
  USERS = 'users',
  COMPANIES = 'companies',
  FUEL_CARDS = 'cards',
  LOGS = 'logs',
  VIOCS = 'viocs',
  CONTRACTS = 'contracts',
  CASH_FLOW = 'cash-flow',
  BANK_EXCHANGE = 'bank-exchange',
  CREATE = 'create',
  SETTINGS = 'settings',
  OVERVIEW = 'overview',
  DATA = 'data',
  LIST = 'list',
  BIND_COMPANIES = 'bind-companies',
  FILTER_COMPANIES = 'filter-companies',
  FILTER_VIOCS = 'filter-viocs',
  FILTER_CONTRACTS = 'filter-contracts',
  BIND_USER = 'bind-user',
  BIND_CARD = 'bind-card',
  UNBIND_CARD = 'unbind',
  BIND_CARD_COMPANIES = 'bind-card-companies',
  CONTRACT_BIND_CARDS = 'contract-bind-cards',
  CARD_BIND_CONTRACTS = 'card-bind-contracts',
  BIND_USER_ROLES = 'bind-user-roles',
  TRANSFER = 'transfer',
  DISCOUNTS = 'discounts',
  REGIONS = 'regions',
  TARIFFS = 'tariffs',
  PRODUCTS = 'products',
  DATA_LIST = 'data/list',
  TRANSACTIONS = 'transactions',
  CHARTS = 'charts',
  AVATAR = 'avatar',
  FEES = 'fees',
  SUMMARY = 'summary',
  INCOME = 'income',
  SPILL_BY_FUEL = 'spillByFuel',
  SPILL_BY_VIOC = 'spillByVioc',
  HISTORY_STATUS_CHANGES = 'history-status-changes',
  ROLE = 'role',
  RESALE = 'resale',
  MANAGERS = 'managers',
  BIND = 'bind',
  PAYOUTS = 'payouts',
  MOTIVATIONS = 'motivations',
  VERSION = 'version',
  RELOAD = 'reload',
}
