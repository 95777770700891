import { createContext } from 'react';

import { Action, ActionGroup } from 'shared/types/Action';
import { Breadcrumb } from 'shared/types/Breadcrumb';

export type ToolbarChangeContextProps = {
  setBreadcrumbs?: (breadcrumbs: Breadcrumb[]) => void;
  setActions?: (actions: Action[]) => void;
  setActionGroups?: (actionGroups: ActionGroup[]) => void;
};

export const ToolbarChangeContext = createContext<ToolbarChangeContextProps>({});
