import { createContext } from 'react';

import { Action, ActionGroup } from 'shared/types/Action';
import { Breadcrumb } from 'shared/types/Breadcrumb';

export type ToolbarContextProps = {
  breadcrumbs?: Breadcrumb[];
  actions?: Action[];
  actionGroups?: ActionGroup[];
};

export const ToolbarContext = createContext<ToolbarContextProps>({});
