import { ReactNode, useState } from 'react';

import { ModalContext, ModalState } from './ModalContext';

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalState, setModalState] = useState<ModalState>({});

  // TO DO
  const openModal = (key: keyof ModalState) => setModalState({ [key]: true });
  const closeModal = (key: keyof ModalState) => setModalState({ [key]: false });

  return (
    <ModalContext.Provider
      value={{
        modalState,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
