import { ReactNode, useMemo, useState } from 'react';

import { Action, ActionGroup } from 'shared/types/Action';
import { Breadcrumb } from 'shared/types/Breadcrumb';

import { ToolbarChangeContext } from './ToolbarChangeContext';
import { ToolbarContext } from './ToolbarContext';

export const ToolbarProvider = ({ children }: { children: ReactNode }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [actions, setActions] = useState<Action[]>([]);
  const [actionGroups, setActionGroups] = useState<ActionGroup[]>([]);

  const contextValue = useMemo(
    () => ({
      breadcrumbs,
      actions,
      actionGroups,
    }),
    [breadcrumbs, actions, actionGroups],
  );

  const changeContextValue = useMemo(
    () => ({
      setBreadcrumbs,
      setActions,
      setActionGroups,
    }),
    [],
  );

  return (
    <ToolbarContext.Provider value={contextValue}>
      <ToolbarChangeContext.Provider value={changeContextValue}>{children}</ToolbarChangeContext.Provider>
    </ToolbarContext.Provider>
  );
};
