import { NavigateOptions, Params, generatePath, useNavigate } from 'react-router-dom';

import { Path } from 'features/common/constants/paths';

import { getQueryParamsStr } from 'shared/utils/queryUtil';

type Options = {
  params?: Params;
  query?: Params;
  state?: NavigateOptions['state'];
  replace?: NavigateOptions['replace'];
};

export const useTo = () => {
  const navigate = useNavigate();

  const to = (basePath: Path, path?: string, options?: Options) => {
    const base = basePath[0] === '/' ? basePath : `/${basePath}`;
    const navigateOptions = {
      pathname: `${generatePath(base + (path ? `/${path}` : ''), options?.params)}`,
      search: options?.query && getQueryParamsStr(options.query),
    };

    return navigate(navigateOptions, {
      state: options?.state,
      replace: options?.replace,
    });
  };

  const toAuth = () => to(Path.AUTH);

  const toDashboard = () => to(Path.DASHBOARD);

  const toUserList = () => to(Path.USERS);
  const toUser = (uuid?: string) => to(Path.USERS, uuid);

  const toCompanyList = () => to(Path.COMPANIES);
  const toCompany = (uuid?: string) => to(Path.COMPANIES, uuid);

  const toFuelCardList = () => to(Path.FUEL_CARDS);
  const toFuelCard = (uuid?: string) => to(Path.FUEL_CARDS, uuid);

  const toViocList = () => to(Path.VIOCS);
  const toVioc = (uuid?: string) => to(Path.VIOCS, uuid);

  const toContractList = () => to(Path.CONTRACTS);
  const toContract = (uuid?: string) => to(Path.CONTRACTS, uuid);

  const toPrevPage = () => navigate(-1);

  return {
    to,

    toAuth,

    toDashboard,

    toUserList,
    toUser,

    toCompanyList,
    toCompany,

    toFuelCardList,
    toFuelCard,

    toVioc,
    toViocList,

    toContractList,
    toContract,

    toPrevPage,
  };
};
