import { Suspense, lazy } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { Path } from 'features/common/constants/paths';

import { PageLoader } from 'shared/ui/loader';

const PublicAccess = lazy(() => import('app/routing/access/public'));
const PrivateAccess = lazy(() => import('app/routing/access/private'));

const AuthLayout = lazy(() => import('features/common/layouts/auth'));

const MainLayout = lazy(() => import('features/common/layouts/main'));

const NotFoundPage = lazy(() => import('features/not-found'));

const AuthPage = lazy(() => import('features/auth/pages'));

const DashboardPage = lazy(() => import('features/dashboard'));

const UserListPage = lazy(() => import('features/user/pages/list'));
const UserProfilePage = lazy(() => import('features/user/pages/profile'));

const CompanyListPage = lazy(() => import('features/company/pages/list'));
const CompanyProfilePage = lazy(() => import('features/company/pages/profile'));

const FuelCardListPage = lazy(() => import('features/fuel-card/pages/list'));
const FuelCardProfilePage = lazy(() => import('features/fuel-card/pages/profile'));

const ViocListPage = lazy(() => import('features/vioc/pages/list'));
const ViocProfilePage = lazy(() => import('features/vioc/pages/profile'));

const ContractListPage = lazy(() => import('features/contract/pages/list'));
const ContractProfilePage = lazy(() => import('features/contract/pages/profile'));

const TariffListPage = lazy(() => import('features/tariff/pages/list'));
const TariffProfilePage = lazy(() => import('features/tariff/pages/profile'));

const CashFlowListPage = lazy(() => import('features/cash-flow/pages/list'));

const BankExchangeListPage = lazy(() => import('features/bank-exchange/pages/list'));
const BankExchangeProfilePage = lazy(() => import('features/bank-exchange/pages/profile'));

const TransactionListPage = lazy(() => import('features/transaction/pages/list'));
const TransactionProfilePage = lazy(() => import('features/transaction/pages/profile'));

const MotivationListPage = lazy(() => import('features/motivation/pages/list'));
const MotivationProfilePage = lazy(() => import('features/motivation/pages/profile'));

const ManagerListPage = lazy(() => import('features/manager/pages/list'));

const PayoutListPage = lazy(() => import('features/payout/pages/list'));

const LogListPage = lazy(() => import('features/log/pages/list'));

export function Routing() {
  const mainRoutes: RouteObject = {
    path: Path.MAIN,
    children: [
      { path: '', element: <Navigate to={Path.DASHBOARD} /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  };

  const authRoutes: RouteObject = {
    path: Path.AUTH,
    element: (
      <PublicAccess>
        <AuthLayout />
      </PublicAccess>
    ),
    children: [
      { path: '*', element: <Navigate to={Path.AUTH} /> },
      { path: '', element: <AuthPage /> },
    ],
  };

  const dashboardRoutes: RouteObject = {
    path: Path.DASHBOARD,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [{ path: '', element: <DashboardPage /> }],
  };

  const userRoutes: RouteObject = {
    path: Path.USERS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <UserListPage /> },
      {
        path: ':id',
        element: <UserProfilePage />,
      },
    ],
  };

  const companyRoutes: RouteObject = {
    path: Path.COMPANIES,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <CompanyListPage /> },
      {
        path: ':id',
        element: <CompanyProfilePage />,
      },
    ],
  };

  const fuelCardRoutes: RouteObject = {
    path: Path.FUEL_CARDS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <FuelCardListPage /> },
      {
        path: ':id',
        element: <FuelCardProfilePage />,
      },
    ],
  };

  const viocRoutes: RouteObject = {
    path: Path.VIOCS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <ViocListPage /> },
      {
        path: ':id',
        element: <ViocProfilePage />,
      },
    ],
  };

  const contractRoutes: RouteObject = {
    path: Path.CONTRACTS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <ContractListPage /> },
      {
        path: ':id',
        element: <ContractProfilePage />,
      },
    ],
  };

  const tariffRoutes: RouteObject = {
    path: Path.TARIFFS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <TariffListPage /> },
      {
        path: ':id',
        element: <TariffProfilePage />,
      },
    ],
  };

  const cashFlowRoutes: RouteObject = {
    path: Path.CASH_FLOW,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [{ path: '', element: <CashFlowListPage /> }],
  };

  const bankExchangeRoutes: RouteObject = {
    path: Path.BANK_EXCHANGE,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <BankExchangeListPage /> },
      { path: ':id', element: <BankExchangeProfilePage /> },
    ],
  };

  const transactionRoutes: RouteObject = {
    path: Path.TRANSACTIONS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <TransactionListPage /> },
      { path: ':id', element: <TransactionProfilePage /> },
    ],
  };

  const motivationRoutes: RouteObject = {
    path: Path.MOTIVATIONS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [
      { path: '', element: <MotivationListPage /> },
      {
        path: ':id',
        element: <MotivationProfilePage />,
      },
    ],
  };

  const managerRoutes: RouteObject = {
    path: Path.MANAGERS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [{ path: '', element: <ManagerListPage /> }],
  };

  const payoutRoutes: RouteObject = {
    path: Path.PAYOUTS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [{ path: '', element: <PayoutListPage /> }],
  };

  const logRoutes: RouteObject = {
    path: Path.LOGS,
    element: (
      <PrivateAccess>
        <MainLayout />
      </PrivateAccess>
    ),
    children: [{ path: '', element: <LogListPage /> }],
  };

  const allRouteObjects: RouteObject[] = [
    mainRoutes,
    authRoutes,
    dashboardRoutes,
    userRoutes,
    companyRoutes,
    fuelCardRoutes,
    viocRoutes,
    contractRoutes,
    tariffRoutes,
    cashFlowRoutes,
    bankExchangeRoutes,
    transactionRoutes,
    motivationRoutes,
    managerRoutes,
    payoutRoutes,
    logRoutes,
  ];

  const routes = useRoutes(allRouteObjects);

  return <Suspense fallback={<PageLoader />}>{routes}</Suspense>;
}
