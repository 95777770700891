import { createContext } from 'react';

export type ModalState = Record<string, boolean | undefined>;

export type ModalContextProps = {
  modalState?: ModalState;
  openModal?: (key: keyof ModalState) => void;
  closeModal?: (key: keyof ModalState) => void;
};

export const ModalContext = createContext<ModalContextProps>({});
