import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from 'features/auth/api/authApi';
import { User } from 'features/user/types/User';

import { Token } from 'shared/constants/tokens';

export interface AuthState {
  currentUser: User | null;
  isAuth: boolean;
  isFetching: boolean;
}

const initialState: AuthState = {
  currentUser: null,
  isAuth: false,
  isFetching: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, action) => {
        state.isAuth = true;

        localStorage.setItem(Token.ACCESS, action.payload.accessToken);
        localStorage.setItem(Token.REFRESH, action.payload.refreshToken);
      })
      .addMatcher(authApi.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
        state.isAuth = true;
        state.currentUser = action.payload;
      })
      .addMatcher(authApi.endpoints.getCurrentUser.matchRejected, state => {
        state.isAuth = false;
        state.currentUser = null;
      })
      .addMatcher(authApi.endpoints.signOut.matchFulfilled, state => {
        state.isAuth = false;
        state.currentUser = null;
      });
  },
});

export const { setCurrentUser, setIsAuth, setIsFetching } = authSlice.actions;

export default authSlice.reducer;
