import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReauth } from 'features/common/http/baseQuery';

export const tagTypes = [
  'User',
  'Company',
  'FuelCard',
  'Log',
  'Vioc',
  'ViocFee',
  'Contract',
  'CashFlow',
  'BankExchange',
  'Tariff',
  'Fuel',
  'Region',
  'Transaction',
  'EmployeeRole',
  'Manager',
  'Payout',
  'Motivation',
] as const;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes,
  endpoints: () => ({}),
});

export type ProvidedItem = Record<string, any>;

export function provideList<R extends ProvidedItem[], T extends (typeof tagTypes)[number]>(
  resultsWithUuids: R | undefined,
  tagType: T,
  idKey: string,
) {
  return resultsWithUuids
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithUuids.map(item => ({ type: tagType, id: item[idKey] }))]
    : [{ type: tagType, id: 'LIST' }];
}
