import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query';

import { TokensDto } from 'features/auth/types/dto/tokens.dto';
import { Path } from 'features/common/constants/paths';

import { Http } from 'shared/constants/http';
import { Token } from 'shared/constants/tokens';

const baseUrl = process.env.REACT_APP_API_URL;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: 'include',
  prepareHeaders: headers => {
    const accessToken = localStorage.getItem(Token.ACCESS);

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === Http.UNAUTHORIZED) {
    const refreshToken = localStorage.getItem(Token.REFRESH);

    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: `${Path.AUTH}/${Path.REFRESH}`,
          method: 'POST',
          body: { refreshToken },
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = refreshResult.data as TokensDto;

        localStorage.setItem(Token.ACCESS, newAccessToken);
        localStorage.setItem(Token.REFRESH, newRefreshToken);

        result = await baseQuery(args, api, extraOptions);
      } else {
        await baseQuery({ url: `${Path.AUTH}/${Path.SIGN_OUT}`, method: 'POST' }, api, extraOptions);
      }
    }
  }

  return result;
};
