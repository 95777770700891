import * as ReactDomClient from 'react-dom/client';
import { ReactNotifications } from 'react-notifications-component';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import App from 'app';
import { persistor, store } from 'app/store';

import ToolbarProvider from 'features/common/providers/toolbar';
import Notifications from 'features/common/widgets/notifications';

import ModalProvider from 'shared/providers/modal';
import ThemeProvider from 'shared/providers/theme';

import '_metronic/scss/style.scss';

const container = document.getElementById('root') as Element;
const root = ReactDomClient.createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider>
          <ModalProvider>
            <ToolbarProvider>
              <ReactNotifications />
              <Notifications />
              <App />
            </ToolbarProvider>
          </ModalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
