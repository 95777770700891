import { api } from 'app/store/api';

import { CheckTwoFactorDto, CheckTwoFactorResDto } from 'features/auth/types/dto/check-two-factor.dto';
import { ResetPasswordDto } from 'features/auth/types/dto/reset-password.dto';
import { SendCodeDto } from 'features/auth/types/dto/send-code.dto';
import { SendResetPasswordDto } from 'features/auth/types/dto/send-reset-password.dto';
import { SignInDto } from 'features/auth/types/dto/sign-in.dto';
import { TokensDto } from 'features/auth/types/dto/tokens.dto';
import { Path } from 'features/common/constants/paths';
import { User } from 'features/user/types/User';

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: `${Path.AUTH}/${Path.CURRENT_USER}`,
        method: 'GET',
      }),
    }),
    signIn: builder.mutation<TokensDto, SignInDto>({
      query: body => ({
        url: `${Path.AUTH}/${Path.SIGN_IN}`,
        method: 'POST',
        body,
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: `${Path.AUTH}/${Path.SIGN_OUT}`,
        method: 'POST',
      }),
      onQueryStarted(_arg, queryApi) {
        queryApi.queryFulfilled.then(() => {
          queryApi.dispatch(authApi.util.resetApiState());
        });
      },
    }),
    checkTwoFactor: builder.mutation<CheckTwoFactorResDto, CheckTwoFactorDto>({
      query: body => ({
        url: `${Path.AUTH}/${Path.TWO_FACTOR}`,
        method: 'POST',
        body,
      }),
    }),
    sendCode: builder.mutation<void, SendCodeDto>({
      query: body => ({
        url: `${Path.AUTH}/${Path.SEND_CODE}`,
        method: 'POST',
        body,
      }),
    }),
    sendResetPassword: builder.mutation<void, SendResetPasswordDto>({
      query: body => ({
        url: `${Path.AUTH}/${Path.SEND_RESET_PASSWORD}`,
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordDto>({
      query: body => ({
        url: `${Path.AUTH}/${Path.RESET_PASSWORD}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useSignInMutation,
  useSignOutMutation,
  useCheckTwoFactorMutation,
  useSendCodeMutation,
  useSendResetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
